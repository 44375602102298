@font-face {
  font-family: 'NutmegHeadline-UltraBlack';
  src:
    url('NutmegHeadline-UltraBlack.woff2') format('woff2'),
    url('NutmegHeadline-UltraBlack.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Text';
  src:
    url('SFProText-Regular.woff2') format('woff2'),
    url('SFProText-Regular.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  font-variant-ligatures: 'no-common-ligatures';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src:
    url('KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2') format('woff2'),
    url('KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-variant-ligatures: 'no-common-ligatures';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    url('KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2') format('woff2'),
    url('KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-variant-ligatures: 'no-common-ligatures';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    url('KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2') format('woff2'),
    url('KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto-Medium';
  font-variant-ligatures: 'no-common-ligatures';
  src:
    url('Roboto-Medium.woff2') format('woff2'),
    url('Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-SemiBold';
  font-variant-ligatures: 'no-common-ligatures';
  src:
    url('Roboto-Medium.woff2') format('woff2'),
    url('Roboto-Medium.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Bold';
  font-variant-ligatures: 'no-common-ligatures';
  src:
    url('Roboto-Bold.woff2') format('woff2'),
    url('Roboto-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Regular';
  font-variant-ligatures: 'no-common-ligatures';
  src:
    url('Roboto-Regular.woff2') format('woff2'),
    url('Roboto-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
